<template>
    <div class="detail-wrap">
        <div class="header">
            <div>
                <span>{{ title }}</span>
            </div>
        </div>

        <div class="content">
            <!-- 基础信息 -->
            <sv-card title="问题" :showLoading="showLoading">
                <div slot="body">
                    <sv-panel-form ref="baseForm" :labelWidth="120">
                        <item type="row">
                            <item type="col">
                                <item label="软件名称" type="displayfield" name="name" />
                                <item label="软件号" type="displayfield" name="code" />
                                <item label="关联零件号" type="displayfield" name="partCode" />
                                <item label="零件名称" type="displayfield" name="partName" />
                            </item>
                            <item type="col">
                                <item label="应用车型" type="displayfield" name="useModelGroupName" span="24" />
                            </item>
                        </item>
                    </sv-panel-form>
                </div>
            </sv-card>

            <!-- 基础信息 -->
            <sv-card title="版本" :showLoading="showLoading">
                <div slot="body">
                    <Button @click="clickBtn('startuse')" :disabled="!buttonStatus || row[0].statusBl"
                        v-permission="'tism:ds:view-use'">启用</Button>
                    <Button @click="clickBtn('stopuse')" :disabled="!buttonStatus || !row[0].statusBl"
                        v-permission="'tism:ds:view-stop'">停用</Button>
                    <Button @click="clickBtn('update')" :disabled="!buttonStatus || row[0].statusBl"
                        v-permission="'tism:ds:view-update'">修改</Button>
                    <Button @click="clickBtn('del')" :disabled="!buttonStatus1"
                        v-permission="'tism:ds:view-delete'">删除</Button>
                    <Button @click="clickBtn('exportvin')" :disabled="!buttonStatus1"
                        v-permission="'tism:ds:view-export'">导出vin明细</Button>
                    <Button @click="clickBtn('download')" :disabled="!buttonStatus"
                        v-permission="'tism:ds:view-import'">下载软件</Button>
                    <Table
                        border
                        ref="table"
                        :columns="columns"
                        :data="data"
                        highlight-row
                        @on-row-click="rowClick"
                        @on-row-dblclick="rowDblClick"
                        @on-selection-change="selectionChange"></Table>
                </div>
            </sv-card>

            <sv-edit ref="edit" title="软件" :labelWidth="90" :width="400" :isShowFooter="false">
                <item type="row">
                    <item type="col">
                        <item name="subject" label="活动主题" type="textbox" :maxlength="100" />
                        <item name="name" label="软件名称" type="textbox" :maxlength="100" disabled="true" />
                        <item name="code" label="软件号" type="textbox" :maxlength="50" disabled="true" />
                        <item name="useVersion" label="当前版本号" type="textbox" :maxlength="50" disabled="true" />
                        <item name="version" label="新版本号" type="textbox" required="true" :maxlength="50" />
                        <item
                            name="vinRes"
                            submitName="vinResId"
                            label="适用VIN码"
                            type="uploadfield"
                            dataType="object"
                            url="/api/ds/res/upload"
                            :format="['xlsx', 'xls']"
                            required="true" />
                        <item
                            name="fileRes"
                            submitName="fileResId"
                            label="添加软件"
                            type="uploadfield"
                            dataType="object"
                            url="/api/ds/res/upload"
                            :maxSize="512000"
                            :format="['zip', 'rar']"
                            required="true" />
                        <item name="id" type="textbox" hidden="true" />
                        <item name="diagnositicsSoftwareId" type="textbox" hidden="true" />
                    </item>
                </item>
                <div slot="footer-btn">
                    <Button @click="doClose()">关闭</Button>
                    <Button type="primary" @click="doSave('save')">保存</Button>
                    <Button type="primary" @click="doSave('apply')">应用</Button>
                </div>
            </sv-edit>
        </div>
    </div>
</template>

<script>
import { request } from '@/network/request';
import config from 'config';

export default {
    name: 'M001-1',
    components: {},
    data() {
        return {
            title: document.title,
            baseData: {},
            showLoading: false,
            canComment: false,
            ftpURL: config.ftpURL,
            faultImagesUrl: [],
            list: [],
            columns: [
                {
                    type: 'selection',
                    width: 60,
                    align: 'center',
                    render: (h) => {
                        return h('Checkbox', {
                            props: {}
                        });
                    }
                },
                {
                    title: '迭代版本',
                    key: 'version'
                },
                {
                    title: '活动主题',
                    key: 'subject'
                },
                {
                    title: '状态',
                    key: 'statusName'
                },
                {
                    title: '维护时间',
                    key: 'modifiedOn',
                    render: (h, params) => {
                        return h('div', this.formatDatetime(params.row.modifiedOn));
                    }
                },
                {
                    title: '启用时间',
                    key: 'enableOn',
                    render: (h, params) => {
                        return h('div', this.formatDatetime(params.row.enableOn));
                    }
                }
            ],
            data: [],
            row: [],
            buttonStatus: false,
            buttonStatus1: false
        };
    },

    computed: {},

    created() {
        this.loadBaseData();
        this.loadTableData();
    },

    methods: {
        loadBaseData() {
            const url = '/ds/diagnostics/software/detail?id=' + this.$route.query.id;
            this.showLoading = true;

            request
                .get(url)
                .then(data => {
                    if (data.success) {
                        const { result } = data;
                        this.baseData = result || {};
                        this.$nextTick(() => {
                            this.$refs.baseForm.setValues(result);
                        });
                    } else {
                        this.$Message.error(data.message);
                    }
                })

                .finally(() => {
                    this.showLoading = false;
                });
        },

        loadTableData() {
            const url = '/ds/diagnostics/software/detail/dsVersion?id=' + this.$route.query.id;
            this.showLoading = true;

            request
                .get(url)
                .then(data => {
                    if (data.success) {
                        const { list } = data;
                        this.data = list || {};
                    } else {
                        this.$Message.error(data.message);
                    }
                })

                .finally(() => {
                    this.showLoading = false;
                });
        },

        // 单击某一行时触发
        rowClick(record, idx) {
            this.$refs.table.selectAll(false);
            this.$refs.table.objData[idx]._isChecked = true;
            this.$refs.table.objData[idx]._isHighlight = true;
            this.row = [record];
            this.buttonStatus = this.getButtonStatus();
            this.buttonStatus1 = this.getButtonStatus1();
        },

        // 双击某一行时触发
        rowDblClick() {
            this.update();
        },

        selectionChange(selection) {
            let data = this.$refs.table.objData;

            for (let key in data) {
                let item = data[key];
                if (item._isChecked) {
                    data[key]._isHighlight = true;
                } else {
                    data[key]._isHighlight = false;
                }
            }
            this.row = selection;
            // this.$refs.table.rowClick(selection);
            this.buttonStatus = this.getButtonStatus();
            this.buttonStatus1 = this.getButtonStatus1();
        },

        getButtonStatus() {
            if (this.row) {
                if (this.row.length === 1) {
                    return true;
                }
                return false;
            }
        },

        getButtonStatus1() {
            if (this.row) {
                if (this.row.length > 0) {
                    return true;
                }
                return false;
            }
        },

        clickBtn(name) {
            switch (name) {
                case 'startuse':
                    this.changeStatus();
                    break;
                case 'stopuse':
                    this.changeStatus();
                    break;
                case 'update':
                    this.update();
                    break;
                case 'del':
                    this.del();
                    break;
                case 'exportvin':
                    this.export(true);
                    break;
                case 'download':
                    this.export(false);
                    break;
                default:
                    break;
            }
        },

        changeStatus() {
            const isMultiVersion = !this.row[0].statusBl;
            const url = '/ds/diagnostics/software/status/update';
            const msg = isMultiVersion
                ? '启用后将停用上一个版本，请确认是否继续启用?'
                : '停用后该刷写软件将受到限制，请确认是否继续停用?';

            const status = { status: !isMultiVersion ? 'DS_STATUS_STOP' : 'DS_STATUS_USEING', id: this.row[0].id };

            this.$Modal.confirm({
                title: '提示',
                content: msg,
                onOk: () => {
                    request
                        .post(url, status)
                        .then(data => {
                            if (data.success) {
                                this.$Message.success('操作成功');
                                this.loadTableData();
                            } else {
                                this.$Message.error(data.message);
                            }
                        })
                        .finally(() => {
                            this.showLoading = false;
                        });
                }
            });
        },

        update() {
            const row = {
                ...this.baseData,
                ...this.row[0]
            };
            this.$refs.edit.show('UPDATE', row);
        },

        doSave(method) {
            this.$refs.edit.doSubmit(method);

            this.$refs.edit.$on('onSubmit', (params, mode, status, callback) => {
                if (mode === 'UPDATE') {
                    this.updateRecord(params, status, callback);
                }
            });
        },

        updateRecord(params, status, callback) {
            let url;

            switch (status) {
                case 'apply':
                    url = '/ds/diagnostics/software/detail/use';
                    break;

                case 'save':
                    url = '/ds/diagnostics/software/detail/update';
                    break;
            }

            request
                .post(url, params)
                .then(data => {
                    this.updateRecordSuccess(data, status, callback);
                })
                .finally(() => { });
        },

        updateRecordSuccess(data, status, callback) {
            if (data.success) {
                if (status === 'apply') {
                    this.$Message.success('应用成功');
                } else {
                    this.$Message.success('操作成功');
                }
                this.loadTableData();
            } else {
                this.$Message.error(data.message);
            }

            if (typeof callback === 'function') {
                callback(data.success);
            }
        },

        del() {
            const url = '/ds/diagnostics/software/detail/delete';
            const msg = '删除后无法恢复，请确认是否继续删除?';

            const row = {
                ids: this.row.map(item => item.id),
                diagnositicsSoftwareId: this.row[0].diagnositicsSoftwareId
            };

            this.$Modal.confirm({
                title: '提示',
                content: msg,
                onOk: () => {
                    request
                        .post(url, row)
                        .then(data => {
                            if (data.success) {
                                this.$Message.success('操作成功');
                                this.loadTableData();
                            } else {
                                this.$Message.error(data.message);
                            }
                        })
                        .finally(() => {
                            this.showLoading = false;
                        });
                }
            });
        },

        export(isVinRes) {
            const msg = isVinRes
                ? '正在导出中，请耐心等待，你可以进行其他操作'
                : '正在下载中，请耐心等待，你可以进行其他操作';

            this.$Modal.confirm({
                title: '提示',
                content: msg,
                onOk: () => {
                    if (isVinRes) {
                        if (this.row.map(item => item.vinRes).length > 0) {
                            this.row.map(item => window.open(item.vinRes.path));
                        } else {
                            this.$Message.error('该版本没有vin文件');
                            return;
                        }
                    } else {
                        if (this.row[0].fileRes) {
                            const file = this.row[0].fileRes.path;
                            window.open(file);
                            this.downloadFile(this.row[0]);
                        } else {
                            this.$Message.error('该版本没有软件文件');
                            return;
                        }
                    }

                }
            });
        },

        downloadFile(row) {
            const url = '/ds/diagnostics/software/download/ds';
            const params = { id: row.id };

            request.post(url, params).then(data => {
                if (!data.success) {
                    this.$Message.error(data.message);
                }
            });
        },

        doClose() {
            this.$refs.edit.doClose();
        }
    }
};
</script>

<style lang="less" scoped>
.detail-wrap {
    height: 100%;
    margin: 0 auto;
    width: 70%;
    min-width: 1100px;
    display: flex;
    flex-direction: column;
    position: relative;

    .header {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #4e4e4e;
        height: 34px;
        line-height: 34px;
        padding: 0 6px;
        font-weight: 600;
        background: #e4e4e4;

        span {
            margin-right: 50px;
        }
    }

    .content {
        flex: 1;
        padding: 5px 0;
    }

    /deep/ .ivu-input.ivu-input-disabled {
        height: 100px !important;
        min-height: 100px !important;
        overflow-y: auto !important;
    }

    /deep/ .slot-class {
        height: 100%;
    }

    /deep/ .ivu-card-head {
        background: #e4e4e4;
    }

    /deep/.ivu-btn {
        margin-right: 6px !important;
        margin-bottom: 5px !important;
    }

    /deep/.displayfield {
        margin-top: 4px;
    }
}
</style>
